import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import useCartState from "../../stores/cart";

const useStyles = makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
    fontFamily: "oswald",
  },
  total: {
    fontWeight: "700",
    fontFamily: "oswald",
  },
  title: {
    marginTop: theme.spacing(2),
    fontFamily: "oswald",
  },
  commentField: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review({ address, paymentMethod, onCommentChange }) {
  const classes = useStyles();
  const { cart, salesTotal } = useCartState();
  
  // State to manage the general comment
  const [generalComment, setGeneralComment] = useState("");

  // Handle change in the general comment field
  const handleCommentChange = (event) => {
    if (event && event.target && event.target.value !== undefined) {
      console.log("Comment changed:", event.target.value); // Debug statement
      setGeneralComment(event.target.value);
      if (onCommentChange) {
        onCommentChange(event.target.value); // Notify parent component or submit handler
      }
    } else {
      console.error("Event target is undefined or does not have a value property");
    }
  };
  
  
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        gutterBottom
        style={{ fontFamily: "oswald", color: "#1d9682" }}
      >
        Order summary
      </Typography>
      <List disablePadding>
        {cart.map(product => (
          <ListItem
            className={classes.listItem}
            key={
              product.customBrandName || product.productName + product.packSize
            }
          >
            <ListItemText
              primary={
                product.type === "pharmaceutical"
                  ? product.customBrandName
                  : product.productName + " " + product.packSize
              }
              secondary={product.quantity}
              style={{ fontFamily: "oswald" }}
            />
            <Typography variant="body2" style={{ fontFamily: "oswald" }}>
              {product.showPrice &&
                (product.discount?.value
                  ? product.sellingPrice *
                    ((100 - product.discount.value) / 100)
                  : product.sellingPrice)}
            </Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            ksh {salesTotal}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Shipping
          </Typography>
          <Typography gutterBottom style={{ fontFamily: "oswald" }}>
            County: {address.county}
          </Typography>
          <Typography gutterBottom style={{ fontFamily: "oswald" }}>
            Street: {address.street}
          </Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          <Grid container>
            <Typography gutterBottom style={{ fontFamily: "oswald" }}>
              Method: {paymentMethod}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* General comment text field */}
      <TextField
        className={classes.commentField}
        label="General Comments"
        multiline
        minRows={4}
        variant="outlined"
        value={generalComment}
        onChange={handleCommentChange}
        fullWidth
      />
    </React.Fragment>
  );
}
