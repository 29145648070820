import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CheckCircle from "@material-ui/icons/CheckCircle"
import Button from "@material-ui/core/Button"
const useStyles = makeStyles(theme => ({
  rootApp: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: '"Oswald", sans-serif',
    fontWeight: 400,
  },
  accordionRoot: {
    borderBottom: "2px solid #F0F6FA",
  },
  root: {
    padding: 0,
    minHeight: 32,
    "&.Mui-expanded": {
      minHeight: 32,
    },
  },
  content: {
    margin: "6px 0",
    "&.Mui-expanded": {
      margin: "6px 0",
    },
  },
  details: {
    padding: "8px 0 24px 0",
  },
  expanded: {
    border: "1px solid red",
  },
  expandIcon: {
    color: "#0074B7",
    padding: "6px 12px",
  },
  selected: {
    border: "1px green solid",
  },
}))
export default function PaymentForm({ paymentMethod, setPaymentMethod }) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography
        variant="h6"
        style={{
          marginBottom: "1em",
          fontFamily: "oswald",
          color: "#1d9682",
        }}
      >
        Payment method
      </Typography>
      <div>
        <Accordion
          square
          elevation={0}
          classes={{ root: classes.accordionRoot }}
          onClick={() => setPaymentMethod("mpesa")}
        >
          <AccordionSummary
            classes={{
              content: classes.content,
              root: classes.root,
              expandIcon: classes.expandIcon,
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Pay with Mpesa{" "}
              {paymentMethod == "mpesa" && (
                <CheckCircle style={{ color: "green" }} />
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.details }}>
           Delivery fees within Nairobi Ksh 250, countrywide varies
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          elevation={0}
          classes={{ root: classes.accordionRoot }}
          onClick={() => setPaymentMethod("delivery")}
        >
          <AccordionSummary
            classes={{
              content: classes.content,
              root: classes.root,
              expandIcon: classes.expandIcon,
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Cash on delivery (items below ksh 2000)
              {paymentMethod == "delivery" && (
                <CheckCircle style={{ color: "green" }} />
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.details }}>
          Delivery fees within Nairobi Ksh 250, countrywide varies
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          elevation={0}
          classes={{ root: classes.accordionRoot }}
          onClick={() => setPaymentMethod("pick")}
        >
          <AccordionSummary
            classes={{
              content: classes.content,
              root: classes.root,
              expandIcon: classes.expandIcon,
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Pick at the store
              {paymentMethod == "pick" && (
                <CheckCircle style={{ color: "green" }} />
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.details }}>
            Store details
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  )
}
